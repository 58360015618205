export default {
  coach: {
    athlete_calendar: {
      system_noty: 'You have new athlete activity below.',
      close: 'Close day',
      open_review: 'Open review & build',
      new_activity: 'New Activity',
      performances: 'Performances:',
      clear_all_notifications: 'Clear all notifications',
      clear_existing_notifications: 'Clear existing notifications',
      to_review: 'To review',
    },
    athlete_review: {
      added_in: 'Added in',
      view_athlete_performances: 'View athlete performances',
      view_athlete_calendar: 'View athlete calendar',
      view_on_leaderboard: 'View on leaderboard',
      marked_as_complete: 'Marked as complete',
      no_matches_found: 'No matches found',
      try_another_search: 'Please try another search.',
      completed: 'Completed',
      missed: 'Missed',
      performances: 'Performances',
      sorry_full: 'Sorry, we couldn’t find a match for your request.',
      sorry_short: 'Sorry, we couldn’t find a match for',
      try_else: 'Try searching for something else.',
      search_by: 'Search by',
      filter: {
        personal_record: 'Personal record',
        benchmark_workout: 'Benchmark workout',
        part: 'Part',
      },
      client: 'Client',
      search_placeholder: {
        search_personal_records: 'Search personal records',
        search_benchmark_workout: 'Search benchmark workout',
        search_part: 'Search part',
        search_client: 'Search client',
        search: 'Search',
      },
      open_builder: 'Open builder',
      close: 'Close',
      update_schedule_tooltip: "Update the program's schedule at the same time",
      copy_part: 'Copy part to Build day',
    },
    benchmarks: {
      form: {
        help: {
          link: 'Link personal record(s)',
        },
        btn_title:
          'Link a personal record or personal records into this part to send personalised targets to each and every athlete following the program. No more hunting for the calculator in the gym.',
      },
      input: {
        add: 'Add',
        please_provide: 'Please provide a valid time: mm:ss',
        you_cant: 'You can’t enter negative value or zero',
      },
      page: {
        head: {
          title: 'Personal records',
          link: 'Show me how they work.',
        },
        main: {
          title: 'Welcome to using personal records!',
          text: 'Placeholder Personal records text…',
        },
      },
      library: {
        name: 'Personal Record Name',
        code: 'Code',
        measure: 'Unit',
        direction: 'Direction',
        group_name: 'Personal Record Group',
        no_data: 'There are no personal records',
        title: 'Add new personal record',
        nav_title: 'Personal records',
        cancel: 'Cancel',
        search: 'Search personal records…',
      },
      instructions: {
        text: 'Using Pprsonal records, code your sessions so athletes can see individualised sessions automatically. Automatic percentages, split times and more!',
        steps: {
          s1: {
            title: 'Step 1 – Choose the personal record',
            text: 'Pick from the central list, or once you’re up to speed, simply type out the personal record code. <mark>CODE</mark>.',
          },
          s2: {
            title: 'Step 2 – Adjust as Required',
            text: 'When the session text goes blue, we know you’ve linked a personal record. Adjust the percentage to your needs and for split times, you can simply adjust the + or – at the end to represent second based adjustments.',
          },
          s3: {
            title: 'Step 3 – Sense-check the session',
            text: "Sense check your sessions with test values to ensure you've achieved the required training intensity.",
          },
          s4: {
            title: "Step 4 – It's over to your athlete",
            text: 'Athletes will be shown targets based on their own Personal record values. If they haven’t recorded a personal record, we’ll let them know.',
            text2:
              'Once recoded, they’ll be shown their personalised session straight away.',
          },
        },
        dont_show: 'Don’t show me this again',
        close: 'Close',
        title: 'Improve Your Coaching Experience',
      },
      preview: {
        popup: {
          content: {
            title: 'Personal records',
            text: 'Use athlete personal record values as preview',
          },
          title: 'Personal Record Athlete Preview',
        },
        preview: 'Preview',
      },
    },
    calendar: {
      clipboard: {
        buttons: {
          save: 'Save as new template',
          add: 'Add to existing template',
          cancel: 'Cancel',
        },
        selected: 'selected',
      },
      day: {
        last_day: 'Last Active Day of Subscription',
        section_types: {
          challenge: 'Benchmark workout',
          score: 'Leaderboard',
        },
        tooltip:
          "No. of athletes who completed all, some, none of the day's work.",
        pre_wod: 'Pre wod',
        post_wod: 'Post wod',

        open_review: 'Open review mode',
        open_build_mode: 'Open build mode',
        add_daily_video: 'Add daily video',
        add_new_part: 'Add new part',
        gowod_link: 'Gowod link',
        insert_benchmark: 'Insert benchmark workout',
        insert_template: 'Insert template',
        paste: 'Paste',
        paste_keeping_structure: 'Paste keeping structure',
      },
      edit_schedule_warming: {
        checkbox: 'Don’t show me this message again',
        button: 'Yes, continue',
        text: 'Even though your viewing {variable} at the moment, remember any schedule updates will apply to all active athletes.',
        title: 'Schedule warning!',
      },
      form: {
        buttons: {
          open: {
            title: 'Review athlete performances at the same time',
            name: 'Open review',
          },
          close: 'Close',
          save: 'Save',
          add_daily_video: 'Add daily video',
          add_new_part: 'Add new part',
          add_gowod: 'Add Gowod',
          insert_benchmark: 'Insert benchmark workout',
          insert_template: 'Insert template',
        },
        unsaved: {
          title: 'Unsaved Changes?',
          ok_title: 'Yes, Discard Changes',
          cancel_title: 'Cancel',
          text: 'You have unsaved changes. Do you want to leave and lose your changes?',
        },
        hotkey: 'or {hotKey} to quick add text based part',
      },
      grid: {
        alerts: {
          inserted: 'Template successfully inserted.',

          benchmark_inserted: 'Benchmark workout was successfully inserted.',
        },
      },
      nav: {
        all: 'All athletes',
        no_results: 'No results',
        no_athletes: 'No athletes',
        expanded: 'Expanded view',
        review: 'Review mode',
        search: 'Search athlete',
      },
      program_info: {
        got_it: 'Got It',
        text: "Now you can start to build out this program's training schedule. You can also access this program's training schedule later from the main program page.",
        dont_show: 'Do not show this message again',
        title: "Your Program's Schedule",
      },
      week: {
        select: 'Select week',
        save: 'Save as new template',
        add: 'Add to existing template',
        save_week: 'Save week as template',
      },
    },
    clients: {
      all: {
        placeholder: {
          active: 'NO ACTIVE Athlets',
          in_trial: 'NO In-trials Athlets',
          scheduled: 'NO Scheduled Athlets',
          pending: 'NO Pending Athlets',
          pending_cancel: 'NO Pending cancel Athlets',
          past: 'NO Past Athlets',
        },
        data_labels: {
          title: 'Name',
          lt: 'LT (Days)',
          lt_tooltip: 'Total calendar days as an active athlete',
          program: 'Program(s)',
          program_tooltip:
            'You can change which program is highlighted here using the filters option above. All stats that flow to the right apply to this program only.',
          completion: 'Completion %',
          completion_list: {
            day_complete: 'Day complete',
            partially_complete: 'Partially complete',
            missed_day: 'Missed day',
            empty_day: 'Empty day',
            day_viewed: 'Day viewed',
          },
          program_ltv: 'Program LTV',
          program_ltv_tooltip:
            'Program lifetime value. The gross earnings and time spent on this specific program.',
          days_trial: 'Days left in trial',
          athlete_status: 'Athlete status',
          name: 'Program name',
          client_status: 'Client status',
          scheduled_start_date: 'Scheduled start date',
          cltv_tooltip:
            "Athlete lifetime value. Total gross athlete earnings across all programs they've followed",
          actions: 'Actions',
          more: 'More',
        },
        today: 'Today',
        add_training: 'Add Training',
        all_programs: 'All programs',
        show_hidden: 'Show hidden programs',
        program_lifetime_value:
          'Program lifetime value. The gross earnings and time spent on this specific program.',
        active_days: 'Active days',
        show: 'Show on the list',
        hide: 'Hide from the list',

        last_day: 'Last {days} days',
        all_time: 'All-time',
      },
      training_status: {
        all_good: 'All Good',
        needs_training: 'Needs training',
        needs_training_tooltip: 'Athlete(s) have less than 3 days of training',
        less_days_left: '< 3 Days left',
        trialing: 'Trialing',
        scheduled: 'Scheduled',
        incomplete: 'Incomplete',
        incomplete_tooltip:
          'Payment has not cleared through Stripe. Stripe will try this purchase for 24 hours. Athlete will be notified via email if it is successful, or if 24 hours have passed and they must try again.',
        missed_payment: 'Missed payment',
        missed_payment_tooltip:
          'Athlete has missed a payment and has 7 days to rectify.',
        canceled_by_coach_eob: 'Canceled by coach (EOB)',
        auto_cancelled: 'Auto Cancelled',
        auto_cancelled_tooltip:
          'We canceled the subscription as payment wasn’t made after 7 days.',
        canceled_by_athlete: 'Canceled by Athlete',
        canceled_by_athlete_tooltip:
          'Athlete decided to cancel their subscription.',
        canceled_by_athlete_eob: 'Canceled by Athlete',
        canceled_by_coach: 'Canceled by Coach',
        canceled_pre_trial: 'Ended pre-trial',
        canceled_pre_trial_tooltip:
          'Athlete canceled their subscription before the end of the trial',
        program_finished: 'Program Finished',
      },
      cancel_subscription: {
        form: {
          buttons: {
            back: 'Back',
            cancel_and_refund: 'Yes, Cancel and Refund',
            cancel_subscription: 'Yes, Cancel Subscription',
            cancel: 'Cancel',
          },
          start_date: 'Program Start Date',
          cancel_immediately: 'Cancel subscription immediately',
          cancel_at_end: 'Cancel at end of billing cycle',

          title: 'Cancel Subscription',
          title_cancelled_refund: 'Subscription cancelled and refund issued.',
          title_subscription_cancelled: 'Subscription cancelled.',
          title_refund: 'Refund Payment',

          step_info:
            'Are you 100% sure you want to cancel this FREE program subscription? The program will be сancelled immediately.',
          cancel:
            "Athlete access to the program will cease from tomorrow. <i>Important note</i>: if you cancel the program before the end of the athlete's usual billing cycle they will NOT automatically receive a refund for the part of the month they haven't received training for. Please consider whether you need to offer a partial refund on the next screen (paid programs only).",
          continue:
            'Athlete access to the program will continue until the natural end of the current billing cycle. At that point no further athlete payment will be taken and access to program content will stop for the athlete.',
          info: {
            text1: 'Are you 100% sure you want to cancel the subscription?',
            text2:
              'Access to the training program will cease immediately, no further training sessions will flow to the athlete.',
            text3:
              'The athlete will continue to receive training sessions from this program until the end of their billing cycle.',
          },
          refund: {
            label: 'Cancel and issue the athlete a refund',
            text: 'Issue a refund at the same time as cancelling their subscription.',
          },
          not_refund: {
            label: 'Cancel the subscription without a refund',
            text: "The athlete doesn't need a refund issued at this time.",
          },
          warning: {
            full_discount:
              "A 100% coupon was applied to the last transaction therefore a refund can't be issued.",
          },
        },
      },
      client_profile: {
        one_off: 'One-off payment',
        monthly: 'Monthly',
        free: 'Free',
        add_training: 'Add Training',
        open_program: 'Open program',
        programs: {
          title: 'Programs',
          search_placeholder: 'Search programs…',
          sort_by: 'Sort by:',
          columns: {
            title: 'Name',
            price: 'Price',
            schedule_started_at: 'Start date',
            schedule_ended_at: 'End date',
            schedule_status: 'Schedule Status',
          },
          no_programs: "Athlete doesn't have any program",
          buttons: {
            close: 'Close',
            apply_sorting: 'Apply sorting',
          },
          filters: {
            label: 'Program Type',
          },
          sorting: {
            option_one: {
              title: 'Program Name',
              value_one_text: 'A - Z',
              value_one_label: 'Name (A - Z)',
              value_two_text: 'Z - A',
              value_two_label: 'Name (Z - A)',
            },
            option_two: {
              title: 'Price',
              value_one_text: 'Hight - Low',
              value_one_label: 'Price (Hight - Low)',
              value_two_text: 'Low - Hide',
              value_two_label: 'Price (Low - Hide)',
            },
            option_three: {
              title: 'Start date',
              value_one_text: 'Ascending',
              value_one_label: 'Start date (Ascending)',
              value_two_text: 'Descending',
              value_two_label: 'Start date (Descending)',
            },
            option_fourth: {
              title: 'End date',
              value_one_text: 'Ascending',
              value_one_label: 'End date (Ascending)',
              value_two_text: 'Descending',
              value_two_label: 'End date (Descending)',
            },
          },
        },
        view_activity: 'View activity',
        back_to_athletes: 'Back to athletes',
        add_training_modal: {
          title: 'Add new training',
          columns: {
            programs: 'Programs',
            status: 'Schedule Status',
          },
          close: 'close',
        },
      },
      layout: {
        change_highlighted_program: 'Change highlighted program',
        title: 'Athletes',
        search_athletes: 'Search athletes…',
        view: 'View',
        description:
          'If your athlete is following more than one program, decide which to highlight on the face of your athlete list. Show the program with the:',
        filters: {
          label: 'Program Type',
        },
        planFilters: {
          first: 'Highest part completion % (7 days)',
          second: 'Highest day completion % (7 days)',
          third: 'Highest price',
        },
      },
      refund: {
        form: {
          title: 'Refund Payment',
          buttons: {
            refund: 'Refund',
            refund_payment: 'Refund Payment',
            close: 'Close',
          },
          text1: 'Refund amount cannot be 0',
          text2: "Refund can't be more than",
          text3: 'Refund is required',
          reason: 'Reason',
          reason_options: {
            default: 'Select a reason',
            one: 'Duplicate',
            two: 'Fraudulent',
            three: 'Requested by customer',
          },
          notify: 'Refund was successfully created.',
        },
      },
    },
    coupons: {
      title: 'Create coupon codes',
      text: 'Boost sales and reward your loyal customers with coupon codes.',
      create: 'Create a coupon code',
      main_title: 'Coupons',
      search_placeholder: 'Search coupons…',
      table_columns: {
        one: 'Coupon',
        two: 'Duration',
        three: 'Start / End Dates',
        four: 'Times Used',
        five: 'Program(s)',
        six: 'Status',
        seven: 'Cadence',
      },
      duration: {
        once: 'one-off',
        forever: 'off forever',
        other: 'off',
      },
      ongoing: 'Ongoing',
      unlim: 'Unlim',

      view_all: 'View All',
      active: 'Active',
      inactive: 'Inactive',
      deactivate_btn: 'Deactivate',
      deactivate: 'Are you sure you want to deactivate this coupon code?',

      filters: {
        type: {
          label: 'Coupon Type',
          option_one: 'All',
          option_two: 'Active',
          option_three: 'Inactive',
        },
        duration: {
          label: 'Duration',
          option_one: 'All',
          option_two: 'Forever',
          option_three: 'One-off',
          option_four: 'Multiple Months',
        },
      },

      create_modal: {
        label_name: 'Coupon Name',
        placeholder_name: 'E.g. DISCOUNT40OFF',
        value: 'Value in %',
        placeholder_value: 'Set a Value',
        must_be: 'Must be between',
        and: 'and',
        placeholder_duration: 'E.g. 12',
        error_duration: 'Please provide a valid Duration.',
        error_date: 'Please provide a valid Date.',
        start: 'Start Date',
        end: 'End Date',
        error_number: 'Please provide a valid number.',
        assign_to_program: 'Assign to Program',
        button_save: 'Save Coupon',
        button_cancel: 'Cancel',
        select_program: 'Select a Program',
        all_programs: 'All Programs',
        coupon_duration_options: {
          first: 'First billing period only / One-off discount',
          second: 'Discount applied for more than one billing period',
          third:
            'Discount applied every billing period as long as subscription is active',
        },
        title: 'Create New Coupon',
        notify: 'Your coupon was succesfully saved.',

        setup:
          'Set up your very own discount codes to offer promotions to new and existing athletes.',
        name_feedback: {
          invalid:
            'May only contain alphanumeric characters in addition to - and _',
          valid: 'Athletes will be required to enter coupon codes on checkout.',
        },
        how_long: 'How long should the discount apply for?',
        duration: 'Duration in months',
        limit_duration:
          'Limit when this code can be used? E.g. Black Friday Special.',
        max_redemptions:
          'Limit how many times this code can be used (in total).',
        redemptions_input:
          'Limit how many times this code can be used (in total).',

        cadence: {
          label: 'Select Cadence',
          options: {
            all: 'All',
            monthly: 'Monthly',
            yearly: 'Yearly',
            placeholder: 'Monthly/Yearly',
          },
          error: 'Cadence is required',
        },
      },
      refund_modal: {
        alert: {
          text1:
            'Stripe will attempt to take the refund from any pending balance you have awaiting bank transfer. If this balance is not sufficient to fulfil the refund, Stripe will automatically try and debit your bank account.',
          text2:
            'Please ensure you have cleared funds in your bank account as if Stripe fails to fulfil the refund you may be charged a $15 fee.',
          text3:
            'You can check what your pending balance is within the dashboard.',
          text4:
            'Refunds can take up to 10 days to process. It is not possible to refund processing fees. It is only possible to make a full or partial refund once per transaction.',
        },
      },
      delete_modal: {
        title: 'Deactivate Coupon',
        cancel_btn: 'Cancel',
        ok_btn: 'Yes, Deactivate',
      },
      choose_program_modal: {
        title: 'All Programs',
        programs: 'Programs',
        close: 'Close',
      },
    },
    challenges: {
      create: 'Create a benchmark workout',
      title: 'Create a benchmark workout',
      description:
        "Create and insert benchmark workouts into multiple programs and we'll collate all the scores into a single leaderboard. Athletes can re-enter benchmark workouts and see how they progress over time too.",
      challenges: {
        edit: 'Edit',
        delete: 'Delete',
        no_scores: 'No scores',
        table: {
          title: 'Benchmark workout',
          scores: 'Scores',
          type: 'Score type',
          description: 'Description',
          created: 'Created',
        },
      },
      insert_challenge: {
        title: 'Choose Benchmark workout',
        tab_one: 'My Library',
        tab_two: 'Pre-Built',
        search_placeholder: 'Search in library…',
        filters: 'Filters',
        create: 'Create a benchmark workout',
        cancel: 'Cancel',
        insert: 'Insert',
      },
      layout: {
        title: 'Benchmark workouts',
      },
      delete_challenge: {
        text: 'Please confirm that you want to delete this benchmark workout.',
        title: 'Are you 100% sure?',
        ok_title: 'Yes, Delete Benchmark workout',
        cancel: 'Cancel',
      },
      my_library: {
        search_placeholder: 'Search benchmark workouts…',
      },
      view_challenge: {
        open: 'Open leaderboard',
        close: 'Close',
      },
    },
    media: {
      title: 'Store all your media and documents here.',
      text: 'You can quickly assign them into any training program you build. Any media added into a program, will also appear here.',
      edit: {
        title: 'Edit media',
        cancel: 'Cancel',
        save: 'Save',
        invalid_feedback: 'Please provide a valid Title.',
        media_title: 'Title',

        subtitles: {
          add: 'Add subtitles',
          remove: 'Remove',
          add_file: 'Add file',

          errors: {
            file_type: 'File format must be *.vtt',
            file_size: 'Max file size is 100 MB',
          },

          select: {
            label: 'Select subtitles language',
            placeholder: 'Select language',
          },

          modal: {
            are_you_sure: {
              title: 'Are you sure?',
              description:
                'Your changes haven’t been saved, are you sure you want to cancel?',

              cancel: 'cancel',
              yes: 'yes, leave without saving',
            },

            remove_subtitles: {
              title: 'Remove subtitles',
              description: 'Are you sure you want to remove these subtitles?',

              cancel: 'cancel',
              yes: 'yes,remove',
            },

            error: {
              file: {
                title: 'You haven’t uploaded a subtitle file',
                description:
                  'You’ve selected to add subtitles to your video but failed to upload a subtitles file. To continue please add the relevant file or remove the subtitle selection.',
              },
              language: {
                title: 'You haven’t selected a language',
                description:
                  'You’ve uploaded a subtitles file but haven’t selected a subtitle language. To continue please select the relevant language or remove the subtitle selection.',
              },
              cancel: 'cancel',
            },
          },
        },
      },
      index: {
        title: 'Media',
        upload_btn: 'Upload New Media',
        view_all_btn: 'View All',
        groups: {
          video: 'My Video',
          youtube: 'My Youtube Links',
          image: 'My Images',
          other: 'My Documents',
        },
      },
      show: {
        search_placeholder: 'Search media…',
        upload_btn: 'Upload New Media',
        no_files: 'There are no files',
        titles: {
          video: 'My Library',
          youtube: 'My Youtube Links',
          image: 'My Images',
          other: 'My Documents',
        },
      },
    },
    profile: {
      coaches: {
        invite_form: {
          title: 'Invite Coach',
          email_label: 'Email',
          email_placeholder: 'Invite coach by email…',
          invalid_feedback:
            'Please use a valid email address format such as name@gmail.com',
          cancel_btn: 'Cancel',
          invite_btn: 'Invite Coach',
          success_notify: 'Your invite was successfully sent.',
        },
        invite_btn: 'Invite a Coach',
        search_placeholder: 'Search coaches…',
        columns: {
          one: 'Coach',
          two: 'Status',
        },
        invite_status: {
          one: 'Active',
          two: 'Rejected',
          three: 'Canceled',
          four: 'Invited',
        },
        resend_btn: 'Resend',
        remove_modal: {
          cancel_btn: 'Cancel',
          ok_btn: 'Yes, Remove Coach',
          title: 'Remove Coach',
        },
        filters: {
          label: 'Invites',
          one: 'All',
          two: 'Active',
          three: 'Invited',
        },
        resend_notify: 'Your invite was successfully sent.',

        title: 'Invite your coaches',
        text: 'Invite coaches to your account to share the workload.',
        sure: "Are you sure you want to remove this coach? This can't be undone and you'll need to send another invitation.",
      },
      info: {
        first_name: 'First Name',
        first_name_placeholder: 'Your First Name',
        last_name: 'Last Name',
        last_name_placeholder: 'Your Last Name',
        email: 'Email',
        email_tooltip: "Please contact us if you'd like your email changed",
        email_placeholder: 'Your Email',
        logout: "We'll save all your data until you come back.",
      },
      password: {
        title: 'Change Password',
      },
      settings: {
        email_label: 'Financial Emails',

        weight_options: {
          label: 'Weight',
          kilogram: {
            text: 'Kg',
            value: 'kilogram',
          },
          pound: {
            text: 'Lbs',
            value: 'pound',
          },
        },

        length_options: {
          label: 'Distance',
          metre: {
            text: 'Metres',
            value: 'meter',
          },
          yard: {
            text: 'Yards',
            value: 'yard',
          },
        },

        info: "We'll always keep your feed totally up to date, but you can decide what type of emails you'll be sent below.",
        is_coach: {
          financial:
            "Receive emails when you've got new athletes, lost athletes, we've blocked athletes due to failed payments and more.",
        },
        not_coach: {
          financial:
            "Receive emails when you sign up and download programs. We'll also let you know if there's any issues with your payments or if you've been refunded by HWPO Training.",
        },
      },
      layout: {
        nav: {
          one: 'General',
          two: 'Password',
          three: 'Coaches',
        },
        title: 'Settings',
      },
    },
    programs: {
      price: {
        set_pricing: 'Set Pricing',
        price_modal: {
          title: 'Set program price',
          radio_one: 'Set price',
          radio_two: 'Set program as free',
          trial_period_tooltip:
            'Give your athletes a trial period. If they cancel before the end of their trial they will not be charged. A fresh billing period will automatically start at the end of the trial if they continue, rather than pro-rating the first payment.',
          trial_options: {
            one: 'No trial period',
            two: '{days} days',
          },
        },

        hint: '*Includes all applicable taxes.',
        message:
          'The first price you set will be used as your default payment.',

        set: {
          yearly: 'Set yearly price',
          currency: 'Set currency',
          trial: 'Set trial period',
        },

        labels: {
          cadence: 'Cadence',
          price: 'Price',
          percentage: 'Percentage',
        },

        errors: {
          price: {
            required: 'Price is required',
            more: 'Price must be more than',
            less: 'Price must be less than',
          },
          percent: 'Percentage saving must be between',
        },

        cadence: {
          monthly: 'Monthly',
          yearly: 'Yearly',
          fixed: 'One-off payment',
          free: 'Free',
        },

        buttons: {
          setPrice: 'Set Price',
          setFree: 'Set program as free',
          close: 'Close',
        },
      },
      list: {
        main_title: 'Programs',
        search_placeholder: 'Search programs…',
        create_btn: 'Create new Program',
        filters_label: 'Program Type',

        state: {
          label: 'Program Condition',
          one: 'All',
          two: 'Published',
          three: 'Draft',
        },

        columns: {
          one: 'Program Info',
          two: 'Price',
          three: 'Athletes',
          four: 'Schedule Status',
        },
        price: {
          free: 'Free',
          set: 'Set Price',
          one: 'One-off payment',
          monthly: 'Monthly',
        },
        no_athletes: 'No Athletes',
        status: {
          in_draft: 'In Draft',
          days_left: '3 Days Left',
          good: 'All Good',
          needs_training: 'Needs Training',
        },
        action: {
          update_schedule: 'Update Schedule',
          edit_program: 'Edit program',
          athletes: 'Athletes',
          clone: 'Clone',
          archive: 'Archive',
          restore: 'Restore',
          delete: 'Delete',
        },
        title: 'Create your first program',
        text: 'Programs are how you deliver your remote coaching services to the world. Mix and match from the available styles.',
      },
      needs_review: {
        title: 'Programs are up to date',
        text: 'It looks like your programs are all up to date. Good work.',
      },
      form: {
        edit_cover_btn: 'Edit cover media',
        delete_cover_btn: 'Delete cover',
        plan_title: {
          placeholder: '[Edit] Add program name',
          invalid_max_length: 'Title must have at most {maxLength} letters.',
          invalid_min_length: 'Title must have at least {minLength} letters.',
          invalid_unique:
            'The program name must not be the same as the name of any additional program variant.',
          invalid_required: 'Title is required.',
        },
        plan_url: {
          label: 'Create custom program URL (Optional)',
          tooltip:
            "Personalise the URL of this program so it's easier for athletes to find. It also looks more professional to share on social media and email.",
          error:
            'Please provide a valid unique URL. Only letters, - and _ symbols.',
          invalid: 'This URL has been already taken. Please try another one.',
        },
        program_type: 'Program type:',
        price_label: 'Price',
        monthly_price_label: 'Monthly Pricing',
        price_required: 'Price is required.',
        yearly_price_label: 'Yearly Pricing',
        trial_label: 'Trial Period',
        release_label: 'Release Date',
        release_alert:
          'The trial period will only be applied to new subscriptions after the launch date.',
        personal_records_label: 'Personal records',

        variations_title: 'additional Program variations',
        advanced_settings_title: 'Advanced Settings',
        gowod_title: 'Program name in gowod',
        visibility_title: 'Athlete Training Visibility',
        visibility_title_tooltip:
          'Set how far in advance athletes can see training',

        affiliate: {
          title: 'affiliate track sharing',
          description:
            'This track can be shared for free by users who purchase the main track.',

          off_label: 'block',
          on_label: 'allow',

          select: {
            label: 'Select track',
            placeholder: 'Select',
            loading: 'Loading…',
            error: {
              required: 'Please select a track',
            },
          },

          release_date_reset_message: 'Release date has been reset',
        },

        footer: {
          exit_btn: 'Exit program creation',
          publish_btn: 'Publish the program',
          cancel_btn: 'Cancel',
          save_btn: 'Save Program',
          cancel_changes_modal: {
            title: 'Cancel Changes?',
            text: 'Are you sure to cancel all changes, all unsaved content will be lost?',
            cancel_btn: 'No, Keep',
            ok_btn: 'Yes, Cancel Changes',
          },
        },

        option_visibility: {
          one: '1 day in advance',
          two: '{days_adv} days in advance',
        },

        coverTypes: {
          title: 'Main program preview',
          description:
            "This is how your cover media will appear in the main program view and in the athlete app on the main calendar screen. <br>The optimum ratio for this area is ''2.45 : 1' (490px : 200px)",
        },

        choose_type: {
          title: 'Create a new program',
          description: 'Choose the type that matches the needs of your program',
          subscription: 'Subscription',
          fixed_label: 'One-off download',
          create_button: 'Create program',
          individual: {
            tooltip:
              'Athletes will sign up on a rolling monthly subscription basis.',
            point_1: 'Program individualised training for your athletes.',
            point_2: 'Review & build new training at the same time.',
            point_3: 'One program - multiple independent athlete schedules.',
            point_4: 'One program per bespoke coaching pricing tier.',
          },
          fixed: {
            tooltip:
              'Athletes pay a one-off price (or download for free) and receive the program in full to their calendar.',
            point_1: 'Create the program content in advance.',
            point_2:
              'Upon download, every athlete will receive the full program.',
            point_3: 'Ideal where there is a definitive start and end goal.',
            point_4: 'And as samples of your subscription based programming.',
          },
          one_many: {
            tooltip:
              'Athletes will sign up on a rolling monthly subscription basis.',
            point_1: 'Athletes follow the same programming at the same time.',
            point_2: 'A single schedule to update with unlimited athletes.',
            point_3: 'Perfect for managing a larger athlete base efficiently.',
            point_4: 'Great for gym-wide programming.',
          },
          membership: {
            tooltip:
              'Athletes will sign up on a rolling monthly subscription basis.',
            point_1:
              'Every athlete starts at the very first session you ever built.',
            point_2: 'A single schedule to update with unlimited athletes.',
            point_3: 'Athletes may be at differing stages of the program.',
            point_4: 'Perfect for training cycles or rehabilitation programs.',
          },
        },
      },
      plan_options: {
        btn_create: 'Create Program variation',
        confirm_delete: {
          text: ' Are you sure you want to delete ',
          text_second: '  variation? This action cannot be undone.',
          title: 'are you sure?',
          ok_btn: 'Yes, Delete',
          cancel_btn: 'Cancel',
        },
        variation_modal: {
          label: 'Program variation name',
          placeholder: 'Type a name',
          feedback: {
            required: 'Field is required',
            max_length: 'Max 20 Characters',
            unique:
              'Variation with name {title} already exists, please input unique name',
          },
          cancel_btn: 'Cancel',
          rename: 'rename',
          create: 'create',
          title: ' program variation',
        },
        delete_variation_modal: {
          title: 'Delete Program variation',
          label_one: 'Delete variation name while keeping sections associated.',
          label_two:
            'Delete program variation name including all program sections associated.',
          move_to: 'Move to',
          disabled_option: 'Select program variation',
          cancel_btn: 'Cancel',
          delete_btn: 'Delete',
        },
      },
      schedule: {
        title: 'Start Programming',
        text: 'Fill your program with training to follow',
        create_btn: 'Create Week',
        clipboard: {
          save_template: 'Save as new template',
          add_to_template: 'Add to existing template',
          selected: 'selected',
          cut_btn_title: 'Cut',
          copy_btn_title: 'Copy',
          delete_btn_title: 'Delete',
          cancel_btn: 'Cancel',
          clear_btn: 'Clear',
          add_week_btn: 'Add week',
          insert_template_btn: 'Insert template',
          save_btn_title: 'Save as template',
          remove_btn_title: 'Remove',
          remove_section_modal: {
            title: 'Delete Part(s)',
            text: "Are you sure you want to deleted the selected part(s)? This action can't be undone.",
            ok_btn: 'Yes, Delete',
            cancel_btn: 'Cancel',
          },
          remove_week_modal: {
            title: 'Delete Week(s)',
            text: "Are you sure you want to deleted the selected week(s)? This action can't be undone.",
            ok_btn: 'Yes, Delete',
            cancel_btn: 'Cancel',
          },
        },
        day: {
          daily_video: 'DAILY VIDEO',
          pre_wod: 'Pre wod',
          post_wod: 'Post wod',
          benchmark_workout: 'Benchmark workout',
          leaderboard: 'Leaderboard',
          open_build_mode_title: 'Open build mode',
          add_daily_video_title: 'Add daily video',
          add_new_part_title: 'Add new part',
          gowod_link_title: 'Gowod link',
          insert_benchmark_title: 'Insert benchmark workout',
          insert_template_title: 'Insert template',
          paste_title: 'Paste',
          paste_keeping_structure_title: 'Paste keeping structure',
        },
        form: {
          close_btn: 'Close',
          save_btn: 'Save',
          unsaved_modal: {
            title: 'Unsaved Changes?',
            ok_btn: 'Yes, Discard Changes',
            cancel_btn: 'Cancel',
          },
        },
        nav: {
          compact: 'Compact',
          detailed: 'Detailed',
        },
        week: {
          copy_btn_title: 'Copy week',
          clear_btn_title: 'Clear week',
          save_btn_title: 'Save week as template',
          delete_btn_title: 'Delete week',
          save_as_new_btn: 'Save as new template',
          add_to_template: 'Add to existing template',
          week: 'Week',
        },
      },
      view: {
        update_schedule_btn: 'Update Schedule',
        program_live: 'Your program is currently live.',
        edit_program_btn: 'Edit Program',
        update_athletes_btn: 'Update your athletes',
        clients: {
          not_activated: 'Haven’t activated it yet',
          no_training_added: 'No training added yet - add now',
          col_zero_label: 'Name',
          col_one_label: 'Start date',
          col_two_label: 'Scheduled start date',
          col_three_label: 'Days left in trial',
          col_four_label: 'End date',
          col_five_label: 'Athlete status',
          col_six_label: 'Actions',
          col_seven_label: 'More',
          col_price_label: 'Price',
          add_btn: 'Add Training',
          cancel_btn: 'Cancel Subscription',
          search_placeholder: 'Search athletes…',
          no_subscriptions: 'No subscriptions found',
        },
        price_change_message: {
          title: 'Update your athletes',
          recomend_text:
            "We recommend that you notify your athletes via a direct message that the program's price has changed and why. Independently we've also sent a default message to the athlete's feed and email about the change.",
          input_label: 'Send this message to current athletes',
          input_placeholder: 'Write a message…',
          skip_btn: 'Skip',
          dont_notify_btn: 'Don’t notify',
          send_message_btn: 'Send message',
        },
        settings: {
          title: 'Settings',
          custome_url_title: 'Customise your program’s URL',
          custome_url_text:
            "Personalise the URL of this program so it's easier for athletes to find. It also looks more professional to share on social media and email.",
          unique_url_feedback:
            'Please provide a valid unique URL. Only letters, - and _ symbols.',
          gowod_title: 'Program name in gowod',
          advance_athletes_title:
            'Set how far in advance athletes can see training',
          affiliate_track_sharing: 'Affiliate track sharing',
          saved_changes: 'Saved Changes',
        },
      },
    },
    templates: {
      layout: {
        title: 'Templates',
        tab_one: 'Days',
        tab_two: 'Weeks',
      },
      select_modal: {
        library_days: {
          no_templates: 'No templates match search criteria',
          col_one: 'Name',
          col_two: 'No. of Parts',
        },
        library_modules: {
          no_templates: 'There are no templates',
          col_one: 'Name',
          col_two: 'No. of Weeks',
        },
        update_template: 'Update Template',
        insert_template: 'Insert Template',
        add_day_template_btn: 'Day template',
        add_week_template_btn: 'Week template',
        search_placeholder: 'Search in library…',
        cancel_btn: 'Cancel',
        notify_templates: 'Templates were succesfully saved.',
        notify: 'Template was succesfully saved.',
      },

      weeks: {
        list: {
          search_placeholder: 'Search templates…',
          col_one: 'Name',
          col_two: 'No. of Weeks',
          notify: 'Template was succesfully saved.',
          confirm_remove_modal: {
            text: "Are you sure you want to delete this week based template? This action can't be undone.",
            title: 'Delete week(s) template',
            ok_btn: 'Yes, Delete',
            cancel_btn: 'Cancel',
          },
        },
      },

      days: {
        session_form: {
          daily_video_btn: 'Add daily video',
          add_part_btn: 'Add new part',
          gowod_btn: 'Gowod link',
          insert_benchmark_btn: 'Insert benchmark workout',
          insert_btn: 'Insert template',
          close_btn: 'Close',
          save_btn: 'Save',
          confirm_title: 'Unsaved Changes',
          confirm_text:
            'You have unsaved changes. Do you want to leave and discard your changes?',
          confirm_ok_btn: 'Yes, Discard Changes',
          confirm_cancel_btn: 'Cancel',
        },
      },

      list: {
        col_one: 'Name',
        col_two: 'No. of Parts',
        delete_section_modal: {
          title: 'Delete template',
          text: "Are you sure you want to delete this template? This action can't be undone.",
          cancel_btn: 'Cancel',
          ok_btn: 'Yes, Delete',
          notify: 'Session was successfully removed.',
        },
        title: 'Create week(s) template',
        text: 'Create blocks of weeks that you can insert in any of your programs.',
      },
      sections: {
        list: {
          title: 'Create day template',
          cta: 'Create Template',
          action: 'Create day template',
          text: 'Create single part templates you can use time and again in your programs.',
        },
      },
    },
  },
  user: {
    my_purchases: {
      col_1: 'Program',
      col_2: 'Purchased via',
      col_3: 'status',
      col_4: 'Original price',
      col_5: 'Start date',
      col_6: 'Renewal date',
      col_7: 'end Date',

      no_purchases_title: 'No purchases',
      no_purchases_text_1: 'Purchase to a program',
      no_purchases_text_2:
        '. Once you purchase, download the HWPO Training app to follow your programming.',
      here_btn: 'here',
      notify: 'Subscription successfully resumed.',

      purchase_row: {
        col_one: 'Status',
        col_two: 'Start date',
        col_three: 'Renewal date',
        col_four: 'End date',
        col_five: 'Original price',
        col_six: 'Purchased via',

        switch_tooltip: 'Switches to {period} billing period on {date}',

        period: {
          yearly: 'yearly',
          month: 'monthly',
        },

        add_discount_action: 'Add a Discount Code',
        continue_action: 'Continue subscription',
        reschedule_action: 'Reschedule',
        reuse_action: 'Reuse',
        start_action: 'Start my Program',
        resume_action: 'Resume Subscription',
        cancel_action: 'Cancel subscription',
        retry_action: 'Retry',
        switch_period_action: 'Switch billing period',
        cancel_switch_action: 'Cancel switch',
        affiliate_details_action: 'View affiliate details',

        add_to_calendar_modal: {
          is_readd:
            'Use the date selector below to change the start date of this program.',
          is_resume:
            'Please select the date you’d like to resume programming from.',
          else: 'Please select the date you’d like the programming to start from.',
          confirm_start: 'confirm start details',
          cancel_btn: 'Cancel',
          is_readd_btn: 'Apply',
          is_resume_btn: 'Resume program',
          else_btn: 'Start program',
          is_resume_title: 'Resume your program',
          else_title: 'Choose when to start your program',
          successfully_rescheduled: 'Program has been successfully rescheduled',
          successfully_added: 'Program successfully added',
        },
        continue_subscription_modal: {
          cancel_btn: 'Cancel subscription',
          continue_btn: 'Continue subscription',
          text_one: "You're nearing the end of your",
          text_two: '30 day trial',
          text_three: 'on',
          text_four: 'HWPO Sweat',
          text_five: 'Click',
          text_six: "'continue subscription'",
          text_seven: 'below to sign up to HWPO Sweat for',
          text_eight: '$20/MONTH',
          text_nine:
            "The subscription will end automatically, if you don't opt in.",
          notify_cancelled: 'Subscription successfully cancelled.',
          notify_updated: 'Subscription successfully updated.',
        },
        discount_modal: {
          label: 'Discount code',
          input_placeholder: 'E.g. DISCOUNT40OFF',
          next_payment: 'Next payment',
          month: 'month',
          text: 'The discount will take affect at your next billing date.',
          close_btn: 'Close',
          apply_btn: 'Apply',
          ok_btn: 'Ok',
          retry_btn: 'Retry',
          add_code: 'Add Discount Code',
          succesfully_added: 'Discount code succesfully added',
          not_added: 'Discount code not added',
        },
        unsubscribe_modal: {
          title: 'Cancel Subscription',
          are_you_sure:
            'Are you sure you want to cancel your subscription to this program?',
          will_continue:
            'Your access to the program will continue until the natural end of the current billing cycle. At that point no further payment will be taken and access to program content will stop.',
          close_btn: 'Close',
          ok_btn: 'Yes, cancel subscription',
          successfully_cancelled: 'Subscription successfully cancelled.',
        },
        switch_period_modal: {
          title: {
            yearly: 'Switch to yearly payment - {price} / year',
            month: 'Switch to monthly payment - {price} / monthly',
          },

          description: {
            month:
              'Your monthly subscription will take effect once your yearly subscription expires on {eob_date}. Your next payment will be taken on this date.',

            yearly:
              'Your payment and switch to a yearly subscription will take effect at your next billing period. Your next payment will be taken on {eob_date}.',
          },

          promo_code:
            'If you have a promo code applied, you will need to apply this again after the switch.',
          continue: 'Do you want to continue?',

          cancel_btn: 'Cancel',
          switch_btn: 'Switch',
        },

        cancel_switch_modal: {
          title: 'Cancel switch',

          description:
            'Your switch to a {period} subscription will be cancelled.',
          promo_code:
            'If you have a promo code applied, you will need to apply this again after the switch cancellation.',
          continue: 'Do you want to continue?',

          close_btn: 'Close',
          ok_btn: 'Yes, cancel switch',
        },
      },

      you_are_hard_worker: {
        title: 'HARD WORKER!',
        text_1: 'Download the',
        text_2: 'HWPO Training app',
        text_3:
          'now, links below. Your program will commence on the HWPO Training app on',
        text_4: 'Your program will commence on the',
        text_5: 'on',
        text_6:
          ". You will not be charged until that date. On that date 7 days of training, starting from DAY 1, will appear in your calendar and you'll always see 7 days in advance.",
        text_7: 'now, links below, and your program will be waiting for you.',
        text_8:
          'Your app log in details are the same as those created when registering for an athlete account previously.',
        text_9: 'Download the app NOW.',

        text_shared_link:
          'Share this link with your members so they can get access to the daily training',
        visit_btn: 'VISIT MY ACCOUNT',
      },
    },
    profile: {
      billing: {
        tab_1: 'My purchases',
        tab_2: 'Payment methods',
        tab_3: 'Billing history',
        tab_4: 'Promo codes',
        main_title: 'Billing',

        promo_codes: {
          search_placeholder: 'Search…',
          no_promocodes_title: 'No Promo Codes',
          no_promocodes_text:
            'You do not have any unused promo codes available at this time. If you think this is incorrect, please contact HWPO live chat support.',
          col_1: 'Promo code',
          col_2: 'Status',
          col_3: 'Details',
          col_4: 'AVAILABLE ON:',
          col_5: 'Date applied',
          col_6: 'Expiration date',

          filters: {
            label: 'PROMO CODES STATUS',
            all: 'All',
            active: 'Active',
            used: 'Used',
            expired: 'Expired',
          },

          modal: {
            title: 'All programs ({value})',
            program_name: 'program name',
            close: 'Close',
          },

          code_row: {
            view_all_btn: 'VIEW ALL',
            copy_btn_title: 'COPY CODE',
            col_1: 'Status',
            col_2: 'DETAILS',
            col_3: 'Program(s)',
            col_4: 'Date applied',
            col_5: 'Expiration date',
          },
        },

        title: 'Review and download your billing history.',
        billing_history: {
          title: 'Transaction history',
          text: 'Once you purchase a program, your history will appear here.',

          history_info: {
            title: 'Last Payment',
            download_pdf_receipt_btn: 'Download PDF Receipt',
            download_pdf_invoice_btn: 'Download PDF Invoice',
            next_payment_date: 'Next Payment Date',
            missed_payments: 'Missed Payments',
          },
          transaction_row: {
            free: 'Free',
            view_details: 'View Details',
            order_id: 'Order ID',
            purchase_date: 'Purchase Date',
            subtotal: 'Subtotal',
            coupon: 'Coupon',
            total: 'Total',
            date: 'Date:',
            billed_to: 'Billed To',
            full_refund: 'Full Refund',
            partial_refund: 'Partial Refund',
          },

          col_one: 'Item',
          col_two: 'Total',
          col_three: 'Order ID',
          col_four: 'Date',
        },
        transactions: {
          title: 'Billing history',
          text: 'Once you purchase training programs, you can download your billing history here.',
        },
        history: {
          info: 'We have not been able to take a required payment from the following program(s):',
        },

        payment: {
          title: 'Payment information.',
          text: 'To make purchases, please attach a payment card to your account. This is saved securely with Stripe.',
          add: 'Add',
          new: 'New',
          update: 'Update',
          use_as_primary: 'Use as primary card',
          add_new_card: 'add new payment method',
          primary_card: 'Primary card',
          primary_card_success: 'Primary card was successfully changed.',
          payment_information: 'Payment information',
          delete_card: {
            are_you_sure:
              'Are you sure you want to delete your payment method?',
            title: 'Delete Payment Method',
            ok_btn: 'Yes, Delete',
            cancel_btn: 'No, Cancel',
            text: 'You have an active track subscription. You must have a payment method on file to maintain these subscriptions:',
          },
        },
      },
      info: {
        profile_info_btn: 'Profile Info',
        first_name_label: 'First Name',
        first_name_placeholder: 'Your First Name',
        first_name_error: 'First Name is required',
        last_name_label: 'Last Name',
        last_name_placeholder: 'Your Last Name',
        last_name_error: 'Last Name is required',
        name_contains_error:
          'Name must contain less than 50 characters and can’t contain any of the following characters: / | \\ + = @ : < > ? ;',

        birthdate_error: 'Please provide a valid Date of Birth.',
        country_error: 'Location is required',
        affiliated_gym_title: 'Affiliated Gym',
        preferred_currency_label: 'Preferred currency',
        change_password_btn: 'change Password',
        delete_modal: {
          text: "Are you sure? Once deleted, all data and information can't be restored. You will lose all training and program data.",
          title: 'Delete account',
          ok_btn: 'yes, delete my account',
          cancel_btn: 'no, keep my account',
        },

        gym_control: {
          label: 'Your Gym',
          default_option: 'Select your gym',
          gym_types_one: 'Home or Garage Gym',
          gym_types_two: 'Unaffiliated Gym',
          gym_types_three: 'Affiliated Gym',
        },

        language: {
          label: 'Language',
        },

        delete_account: 'delete account',
      },
      payment: {
        title: 'Add payment information',
        text: 'Link a card to your account so you can purchase training programs.',
      },
    },
    purchases: {
      form: {
        title: 'Checkout',
        summary: 'Summary',
        free: 'Free',
        original_price: 'Original Price',
        coupon_code: 'Coupon Code',
        have_coupon: 'Have a Promo Code?',
        coupon_label: 'Your Coupon Code',
        coupon_placeholder: 'e.g. earlybird10',
        update_total: 'Update your total',
        coupon_invalid: 'Please provide a valid Coupon Code.',
        free_trial_period: 'Free trial period',
        days: 'days',
        total: 'Total',
        payment: 'Payment',
        payment_alt: 'Powered by Stripe',
        add_new_payment: 'Add New Payment Method',
        get_for_free: 'Get for free',
        subscribe_for_free: 'Subscribe for free',
        try_payment_again: 'Try Payment Again',
        confirm_payment: 'Confirm payment',
        pay_by_card: 'Pay by card',
        program_purchased: 'Program has been successfully purchased.',
        successfully_subscribed:
          'You have been successfully subscribed to the Program.',

        restart_program_modal: {
          title: 'Resume Subscription',
          text_1:
            'Hey! Good to see you again. We can see that you followed <b>{plan_title}</b> previously.',
          text_2:
            'Would you like to start the program from <b>DAY 1</b> or from where you left off?',
          start_btn: 'Start from the very beginning',
          resume_btn: 'Resume from where i left off',
        },
        move_from_hybrid: {
          title: 'Start your program',
          start_btn: 'Start program',
          text_1:
            "Please select the date you'd like the programming to start from.",
          text_2: 'Your first payment will be taken on: ',
          text_3: '. Only at this point will programming appear in the app.',
          label: 'confirm start details',
        },

        discount_percentage: '( {percentage}% off)',
        off_percentage: '{percentage}% Off',

        info: 'Estimated conversion. Actual may differ.',
        success: 'Your coupon code has been successfully accepted.',

        show_more: 'show more',
        show_less: 'show less',

        pay_by: 'Or pay by card',

        apply_promo_code: 'Apply Promo code',
        promo_code_applied: 'Promo code applied',
      },
    },
    settings: {
      tab_one: 'Default units',
      tab_two: 'Emails',
      title: 'Settings',
    },

    affiliate_track: {
      main_title: 'Affiliate member track',
      back_btn: 'Back to My Purchases',

      nav: {
        your_athletes: 'your athletes',
      },
    },
  },
  controls: {
    signout_btn: 'Log out',
    edit_password: {
      old_password_label: 'Old Password',
      new_password_label: 'New Password',
      repeat_new_password_label: 'Repeat New Password',
      change_btn: 'Change Password',
      error_min_value: 'Password is too short (minimum is 6 characters)',
      error_not_match: 'Passwords do not match',
    },
  },
  update_your_profile_info: {
    title: 'Update your profile info',
  },
};
